<template>
  <section class="userDetails">
    <h4 class="mb-2 ml-5 mt-2">Details</h4>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label class="vs-input--label">Date of Birth</label>
          <datepicker
            format="dd/MM/yyyy"
            v-model="createUserData.dateOfBirth"
          ></datepicker>

          <input
            type="hidden"
            name="dateOfBirth"
            v-model="dateOfBirthModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('contactNumber') &&
              createUserData.contactNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('contactNumber') ? true : false"
            v-validate="'required'"
            name="contactNumber"
            :danger-text="errors.first('contactNumber')"
            data-vv-as="contact number"
            label="Contact #"
            icon-no-border
            icon="icon icon-phone"
            icon-pack="feather"
            class="w-full"
            v-model="contactNumberModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            val-icon-success="done"
            val-icon-danger="error"
            name="abn"
            data-vv-as="ABN"
            :label="abnLabel"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('abn')"
            v-model="abnModal"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row v-show="createUserData.userType === 'doctor'">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label class="vs-input--label">Address</label>
          <div id="geocoder"/>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            name="abn"
            label="Dr Credentials"
            icon-no-border
            class="w-full"
            v-model="drCredentials"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
          <doctor-type @changeDoctorType="changeDoctorType" />
        </div>
      </vs-col>
    </vs-row>

    <vs-row v-if="createUserData.apType === 'Doctor' && $isAuRegion()">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('prescriberNumber') &&
              createUserData.prescriberNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('prescriberNumber') ? true : false"
            v-validate="'required'"
            name="prescriberNumber"
            :danger-text="errors.first('prescriberNumber')"
            data-vv-as="prescriber number"
            label="Prescriber Number"
            icon-no-border
            icon="icon icon-briefcase"
            icon-pack="feather"
            class="w-full"
            v-model="prescriberNumberModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion()">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('ahpraRegistrationNumber') &&
              createUserData.ahpraRegistrationNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('ahpraRegistrationNumber') ? true : false"
            v-validate="'required'"
            name="ahpraRegistrationNumber"
            :danger-text="errors.first('ahpraRegistrationNumber')"
            data-vv-as="AHPRA registration number"
            label="AHPRA #"
            icon-no-border
            icon="icon icon-briefcase"
            icon-pack="feather"
            class="w-full"
            v-model="ahpraRegistrationNumberModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion()">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label class="vs-input--label">AHPRA Expiry date</label>
          <datepicker
            format="dd/MM/yyyy"
            :disabled-dates="disabledDates"
            v-model="ahpraExiryDateModel"
            :input-class="{
              'is-true':
                !errors.has('ahpraExiryDate') && createUserData.ahpraExiryDate,
              'is-danger': errors.has('ahpraExiryDate'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="createUserData.ahpraExiryDate"
                class="
                  bg-color-done
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('ahpraExiryDate')"
                class="
                  bg-color-error
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </datepicker>
          <span class="text-danger" style="font-size: 0.75em">{{
            errors.first("ahpraExiryDate")
          }}</span>
          <input
            type="hidden"
            data-vv-as="AHPRA expiry date"
            data-vv-validate-on="change"
            name="ahpraExiryDate"
            v-validate="'required'"
            v-model="ahpraExiryDateModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion()">
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label>AHPRA Certificate</label>
          <div
            :class="{
              'ahpra-certificate-error': errors.has('ahpraCertificate'),
            }"
          >
            <div class="mb-4" v-if="createUserData.ahpraCertificate">
              <div
                v-if="
                  determineFileType(createUserData.ahpraCertificate) === 'pdf'
                "
                style="margin: auto; width: 50%"
              >
                <a :href="createUserData.ahpraCertificate" target="_blank"
                  >Click here to view file</a
                >
              </div>
              <div v-else style="margin: auto" class="large-square-img-wrapper">
                <img :src="createUserData.ahpraCertificate" class="img-fluid" />
              </div>
            </div>
            <div
              :class="{ 'mt-3': createUserData.ahpraCertificate }"
              style="text-align: center; position: relative"
            >
              <label
                id="ahpraUpload"
                class="vs-con-loading__container"
                :class="{ 'six-pixel': errors.has('ahpraCertificate') }"
                style="
                  border: 1px solid #cccccc;
                  display: inline-block;
                  width: 100%;
                  border-radius: 5px;
                  padding: 10px;
                "
              >
                Upload Document
                <input
                  type="file"
                  @change="uploadImage($event, 'ahpraUpload')"
                  id="file-input"
                  hidden
                  v-validate="'required'"
                  data-vv-as="AHPRA certificate"
                  data-vv-validate-on="change"
                  name="ahpraCertificate"
                />
              </label>
            </div>
          </div>
          <span class="text-danger" style="font-size: 0.75em">{{
            errors.first("ahpraCertificate")
          }}</span>
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion() || $isNZRegion()">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('indemnityInsuranceProvider') &&
              createUserData.indemnityInsuranceProvider != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('indemnityInsuranceProvider') ? true : false"
            v-validate="'required'"
            :danger-text="errors.first('indemnityInsuranceProvider')"
            name="indemnityInsuranceProvider"
            data-vv-as="indeminity provider"
            label="Insurance Provider"
            class="w-full"
            icon-no-border
            icon="icon icon-file"
            icon-pack="feather"
            v-model="indemnityInsuranceProviderModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion() || $isNZRegion()">
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('indemnityInsuranceNumber') &&
              createUserData.indemnityInsuranceNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('indemnityInsuranceNumber') ? true : false"
            v-validate="'required'"
            name="indemnityInsuranceNumber"
            :danger-text="errors.first('indemnityInsuranceNumber')"
            data-vv-as="indemnity number"
            label="Indemnity Number"
            class="w-full"
            icon-no-border
            icon="icon icon-file"
            icon-pack="feather"
            v-model="indemnityInsuranceNumberModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion() || $isNZRegion()">
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label class="vs-input--label">Insurance Expiry</label>
          <datepicker
            format="dd/MM/yyyy"
            :disabled-dates="disabledDates"
            v-model="createUserData.indemnityInsuranceExpiry"
            :input-class="{
              'is-true':
                !errors.first('indemnityInsuranceExpiry') &&
                createUserData.indemnityInsuranceExpiry,
              'is-danger': errors.first('indemnityInsuranceExpiry'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="createUserData.indemnityInsuranceExpiry"
                class="
                  bg-color-done
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('indemnityInsuranceExpiry')"
                class="
                  bg-color-error
                  input-icon-validate
                  vs-input--icon-validate
                "
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </datepicker>
          <span class="text-danger" style="font-size: 0.75em">{{
            errors.first("indemnityInsuranceExpiry")
          }}</span>
          <input
            type="hidden"
            data-vv-as="Insurance Expiry"
            data-vv-validate-on="change"
            name="indemnityInsuranceExpiry"
            v-validate="'required'"
            v-model="indemnityInsuranceExpiryModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row  v-if="$isAuRegion() || $isNZRegion()">
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label>Insurance Certificate</label>
          <div
            :class="{
              'insurance-certificate-error': errors.has('insuranceCertificate'),
            }"
          >
            <div class="mb-4" v-if="createUserData.insuranceCertificate">
              <div
                v-if="
                  determineFileType(createUserData.insuranceCertificate) ===
                  'pdf'
                "
                style="margin: auto; width: 50%"
              >
                <a :href="createUserData.insuranceCertificate" target="_blank"
                  >Click here to view file</a
                >
              </div>
              <div v-else style="margin: auto" class="large-square-img-wrapper">
                <img
                  :src="createUserData.insuranceCertificate"
                  class="img-fluid"
                />
              </div>
            </div>
            <div
              :class="{ 'mt-3': createUserData.insuranceCertificate }"
              style="text-align: center; position: relative"
            >
              <label
                id="insuranceUpload"
                class="vs-con-loading__container"
                :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
                style="
                  border: 1px solid #cccccc;
                  display: inline-block;
                  width: 100%;
                  border-radius: 5px;
                  padding: 10px;
                "
              >
                Upload Document
                <input
                  type="file"
                  @change="uploadImage($event, 'insuranceUpload')"
                  id="file-input"
                  hidden
                  v-validate="'required'"
                  data-vv-as="Insurance certificate"
                  data-vv-validate-on="change"
                  name="insuranceCertificate"
                />
              </label>
            </div>
          </div>
          <span class="text-danger" style="font-size: 0.75em">{{
            errors.first("insuranceCertificate")
          }}</span>
        </div>
      </vs-col>
    </vs-row>
  </section>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "@/axios";
import DoctorType from "../../components/doctors/doctorType";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name: "CreateUserNurse",
  components: { Datepicker, DoctorType },
  inject: ["$validator"],
  props: ["createUserData"],
  computed: {
    abnLabel(){
      return process.env.VUE_APP_REGION === "NZ" ? 'NZBN (Optional)' : 'ABN (Optional)';
    },
    dateOfBirthModel: {
      get() {
        return this.createUserData.dateOfBirth;
      },
      set(val) {
        this.$emit("data-updated", "dateOfBirth", val);
      },
    },
    contactNumberModel: {
      get() {
        return this.createUserData.contactNumber;
      },
      set(val) {
        this.$emit("data-updated", "contactNumber", val);
      },
    },
    abnModal: {
      get() {
        return this.createUserData.abn;
      },
      set(val) {
        this.$emit("data-updated", "abn", val);
      },
    },
    drCredentials: {
      get() {
        return this.createUserData.drCredentials;
      },
      set(val) {
        this.$emit("data-updated", "drCredentials", val);
      },
    },
    prescriberNumberModel: {
      get() {
        return this.createUserData.prescriberNumber;
      },
      set(val) {
        this.$emit("data-updated", "prescriberNumber", val);
      },
    },
    ahpraRegistrationNumberModel: {
      get() {
        return this.createUserData.ahpraRegistrationNumber;
      },
      set(val) {
        this.$emit("data-updated", "ahpraRegistrationNumber", val);
      },
    },
    ahpraExiryDateModel: {
      get() {
        return this.createUserData.ahpraExiryDate;
      },
      set(val) {
        this.$emit("data-updated", "ahpraExiryDate", val);
      },
    },
    indemnityInsuranceProviderModel: {
      get() {
        return this.createUserData.indemnityInsuranceProvider;
      },
      set(val) {
        this.$emit("data-updated", "indemnityInsuranceProvider", val);
      },
    },
    indemnityInsuranceNumberModel: {
      get() {
        return this.createUserData.indemnityInsuranceNumber;
      },
      set(val) {
        this.$emit("data-updated", "indemnityInsuranceNumber", val);
      },
    },
    indemnityInsuranceExpiryModel: {
      get() {
        return this.createUserData.indemnityInsuranceExpiry;
      },
      set(val) {
        this.$emit("data-updated", "indemnityInsuranceExpiry", val);
      },
    },
  },
  data() {
    return {
      disabledDates: { to: new Date() },
      addressItems: [],
      suburbSearchDebounce: undefined,
      dobErrorText: "",
      region: process.env.VUE_APP_REGION,
      accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    };
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
    this.autoCompleteFunc("a");

    mapboxgl.accessToken = this.accessToken;
    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: 'address',
      countries: this.regionType(),
      placeholder: this.createUserData.address.displayAddress
    });

    geocoder.addTo('#geocoder');
    geocoder.on('result', (e) => {
      const address = {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      }
      address.suburb = e.result.context.filter(item=>item.id.includes(this.region === 'US' ? 'district' : 'locality'))[0].text
      address.state = e.result.context.filter(item=>item.id.includes("region"))[0]["text_en-US"]
      address.postCode = e.result.context.filter(item=>item.id.includes("postcode"))[0].text
      address.displayAddress = e.result.place_name
      address.geometry.coordinates.push(e.result.geometry.coordinates[0]);
      address.geometry.coordinates.push(e.result.geometry.coordinates[1]);

      this.$emit("data-updated", "address", address);
    });
  },
  methods: {
    handleSuburbSearch(searching) {
      if (this.suburbSearchDebounce) {
        clearTimeout(this.suburbSearchDebounce);
        this.suburbSearchDebounce = undefined;
      }
      this.suburbSearchDebounce = setTimeout(
        () => this.autoCompleteFunc(searching),
        500
      );
    },
    uploadImage(event, key) {
      this.$vs.loading({
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      this.$emit("upload-image", event, key);
    },
    determineFileType(str) {
      if (str && str.length > 0) {
        var res = str.substring(str.length - 3, str.length);
        return res;
      }
    },
    changeDoctorType(type) {
      this.$emit("data-updated", "doctorType", type);
    },
    autoCompleteFunc(text) {
      if (text.length > 0) {
        this.addressItems = [];
        axios
          .get("/api/v1/getSuburbs", { params: { searchText: text } })
          .then((response) => {
            if (response.data.data.docs.length > 0) {
              const arr = response.data.data.docs.map((singleItem) => {
                singleItem.text = singleItem.displayAddress;
                singleItem.value = singleItem.displayAddress;
                return singleItem;
              });
              this.addressItems = arr;
            }
          });
      }
    },
    regionType() {
      const regions = {
        US: "us",
        AU: "au",
        NZ: "nz"
      }
      return regions[this.region]
    }
  },
};
</script>

<style lang="scss">
  .userDetails {
    .mapboxgl-ctrl-geocoder  {
      width: 100%!important;
      max-width: unset!important;
      box-shadow: none;
      font-family: inherit;

      input {
        padding: 8px 10px 8px 30px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        font-size: 1rem;
        font-family: inherit;
        height: 40px;
      }

      &--icon {
        fill: #cfcfcf;

        &-search {
          top: 10px;
          width: 20px;
          height: 20px;
        }
      }

      .suggestions{
        z-index: 10;
        position: relative;
      }
    }
  }
</style>
