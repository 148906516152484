var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h4',{staticClass:"mb-2 ml-5 mt-2"},[_vm._v("Nurse Details")]),_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Date of Birth")]),_c('datepicker',{attrs:{"format":"dd/MM/yyyy","input-class":{
            'is-true':
              !_vm.errors.has('dateOfBirth') && _vm.createUserData.dateOfBirth,
            'is-danger': _vm.errors.has('dateOfBirth'),
          }},model:{value:(_vm.createUserData.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.createUserData, "dateOfBirth", $$v)},expression:"createUserData.dateOfBirth"}},[_c('template',{slot:"afterDateInput"},[(_vm.createUserData.dateOfBirth)?_c('span',{staticClass:"bg-color-done input-icon-validate vs-input--icon-validate"},[_c('i',{staticClass:"vs-icon notranslate icon-scale material-icons null",attrs:{"valiconpack":"material-icons"}},[_vm._v("done")])]):_vm._e(),(_vm.errors.has('dateOfBirth'))?_c('span',{staticClass:"bg-color-error input-icon-validate vs-input--icon-validate"},[_c('i',{staticClass:"vs-icon notranslate icon-scale material-icons null",attrs:{"valiconpack":"material-icons"}},[_vm._v("error")])]):_vm._e()])],2),_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"0.75em"}},[_vm._v(_vm._s(_vm.errors.first("dateOfBirth") || _vm.dobErrorText))]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.dateOfBirthModel),expression:"dateOfBirthModel"}],attrs:{"type":"hidden","data-vv-as":"Date of Birth","data-vv-validate-on":"change","name":"dateOfBirth"},domProps:{"value":(_vm.dateOfBirthModel)},on:{"input":function($event){if($event.target.composing)return;_vm.dateOfBirthModel=$event.target.value}}})],1)])],1),_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('contactNumber') &&
            _vm.createUserData.contactNumber != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('contactNumber') ? true : false,"name":"contactNumber","danger-text":_vm.errors.first('contactNumber'),"data-vv-as":"contact number","label":"Contact #","icon-no-border":"","icon":"icon icon-phone","icon-pack":"feather"},model:{value:(_vm.contactNumberModel),callback:function ($$v) {_vm.contactNumberModel=$$v},expression:"contactNumberModel"}})],1)])],1),(_vm.$isAuRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('ahpraRegistrationNumber') &&
            _vm.createUserData.ahpraRegistrationNumber != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('ahpraRegistrationNumber') ? true : false,"name":"ahpraRegistrationNumber","danger-text":_vm.errors.first('ahpraRegistrationNumber'),"data-vv-as":"AHPRA registration number","label":"AHPRA #","icon-no-border":"","icon":"icon icon-briefcase","icon-pack":"feather"},model:{value:(_vm.ahpraRegistrationNumberModel),callback:function ($$v) {_vm.ahpraRegistrationNumberModel=$$v},expression:"ahpraRegistrationNumberModel"}})],1)])],1):_vm._e(),(_vm.$isAuRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("AHPRA Expiry date")]),_c('datepicker',{attrs:{"format":"dd/MM/yyyy","disabled-dates":_vm.disabledDates,"input-class":{
            'is-true':
              !_vm.errors.has('ahpraExiryDate') && _vm.createUserData.ahpraExiryDate,
            'is-danger': _vm.errors.has('ahpraExiryDate'),
          }},model:{value:(_vm.ahpraExiryDateModel),callback:function ($$v) {_vm.ahpraExiryDateModel=$$v},expression:"ahpraExiryDateModel"}},[_c('template',{slot:"afterDateInput"},[(_vm.createUserData.ahpraExiryDate)?_c('span',{staticClass:"bg-color-done input-icon-validate vs-input--icon-validate"},[_c('i',{staticClass:"vs-icon notranslate icon-scale material-icons null",attrs:{"valiconpack":"material-icons"}},[_vm._v("done")])]):_vm._e(),(_vm.errors.has('ahpraExiryDate'))?_c('span',{staticClass:"bg-color-error input-icon-validate vs-input--icon-validate"},[_c('i',{staticClass:"vs-icon notranslate icon-scale material-icons null",attrs:{"valiconpack":"material-icons"}},[_vm._v("error")])]):_vm._e()])],2),_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"0.75em"}},[_vm._v(_vm._s(_vm.errors.first("ahpraExiryDate")))]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.ahpraExiryDateModel),expression:"ahpraExiryDateModel"}],attrs:{"type":"hidden","data-vv-as":"AHPRA expiry date","data-vv-validate-on":"change","name":"ahpraExiryDate"},domProps:{"value":(_vm.ahpraExiryDateModel)},on:{"input":function($event){if($event.target.composing)return;_vm.ahpraExiryDateModel=$event.target.value}}})],1)])],1):_vm._e(),(_vm.$isAuRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('label',[_vm._v("AHPRA Certificate")]),_c('div',{class:{
            'ahpra-certificate-error': _vm.errors.has('ahpraCertificate'),
          }},[(_vm.createUserData.ahpraCertificate)?_c('div',{staticClass:"mb-4"},[(
                _vm.determineFileType(_vm.createUserData.ahpraCertificate) === 'pdf'
              )?_c('div',{staticStyle:{"margin":"auto","width":"50%"}},[_c('a',{attrs:{"href":_vm.createUserData.ahpraCertificate,"target":"_blank"}},[_vm._v("Click here to view file")])]):_c('div',{staticClass:"large-square-img-wrapper",staticStyle:{"margin":"auto"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.createUserData.ahpraCertificate}})])]):_vm._e(),_c('div',{class:{ 'mt-3': _vm.createUserData.ahpraCertificate },staticStyle:{"text-align":"center","position":"relative"}},[_c('label',{staticClass:"vs-con-loading__container",class:{ 'six-pixel': _vm.errors.has('ahpraCertificate') },staticStyle:{"border":"1px solid #cccccc","display":"inline-block","width":"100%","border-radius":"5px","padding":"10px"},attrs:{"id":"ahpraUpload"}},[_vm._v(" Upload Document "),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"file","id":"file-input","hidden":"","data-vv-as":"AHPRA certificate","data-vv-validate-on":"change","name":"ahpraCertificate"},on:{"change":function($event){return _vm.uploadImage($event, 'ahpraUpload')}}})])])]),_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"0.75em"}},[_vm._v(_vm._s(_vm.errors.first("ahpraCertificate")))])])])],1):_vm._e(),(_vm.$isAuRegion() || _vm.$isNZRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('indemnityInsuranceProvider') &&
            _vm.createUserData.indemnityInsuranceProvider != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('indemnityInsuranceProvider') ? true : false,"danger-text":_vm.errors.first('indemnityInsuranceProvider'),"name":"indemnityInsuranceProvider","data-vv-as":"indeminity provider","label":"Insurance Provider","icon-no-border":"","icon":"icon icon-file","icon-pack":"feather"},model:{value:(_vm.indemnityInsuranceProviderModel),callback:function ($$v) {_vm.indemnityInsuranceProviderModel=$$v},expression:"indemnityInsuranceProviderModel"}})],1)])],1):_vm._e(),(_vm.$isAuRegion() || _vm.$isNZRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('indemnityInsuranceNumber') &&
            _vm.createUserData.indemnityInsuranceNumber != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('indemnityInsuranceNumber') ? true : false,"name":"indemnityInsuranceNumber","danger-text":_vm.errors.first('indemnityInsuranceNumber'),"data-vv-as":"indemnity number","label":"Indemnity Number","icon-no-border":"","icon":"icon icon-file","icon-pack":"feather"},model:{value:(_vm.indemnityInsuranceNumberModel),callback:function ($$v) {_vm.indemnityInsuranceNumberModel=$$v},expression:"indemnityInsuranceNumberModel"}})],1)])],1):_vm._e(),(_vm.$isAuRegion() || _vm.$isNZRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Insurance Expiry")]),_c('datepicker',{attrs:{"format":"dd/MM/yyyy","disabled-dates":_vm.disabledDates,"input-class":{
            'is-true':
              !_vm.errors.first('indemnityInsuranceExpiry') &&
              _vm.createUserData.indemnityInsuranceExpiry,
            'is-danger': _vm.errors.first('indemnityInsuranceExpiry'),
          }},model:{value:(_vm.createUserData.indemnityInsuranceExpiry),callback:function ($$v) {_vm.$set(_vm.createUserData, "indemnityInsuranceExpiry", $$v)},expression:"createUserData.indemnityInsuranceExpiry"}},[_c('template',{slot:"afterDateInput"},[(_vm.createUserData.indemnityInsuranceExpiry)?_c('span',{staticClass:"bg-color-done input-icon-validate vs-input--icon-validate"},[_c('i',{staticClass:"vs-icon notranslate icon-scale material-icons null",attrs:{"valiconpack":"material-icons"}},[_vm._v("done")])]):_vm._e(),(_vm.errors.has('indemnityInsuranceExpiry'))?_c('span',{staticClass:"bg-color-error input-icon-validate vs-input--icon-validate"},[_c('i',{staticClass:"vs-icon notranslate icon-scale material-icons null",attrs:{"valiconpack":"material-icons"}},[_vm._v("error")])]):_vm._e()])],2),_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"0.75em"}},[_vm._v(_vm._s(_vm.errors.first("indemnityInsuranceExpiry")))]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.indemnityInsuranceExpiryModel),expression:"indemnityInsuranceExpiryModel"}],attrs:{"type":"hidden","data-vv-as":"Insurance Expiry","data-vv-validate-on":"change","name":"indemnityInsuranceExpiry"},domProps:{"value":(_vm.indemnityInsuranceExpiryModel)},on:{"input":function($event){if($event.target.composing)return;_vm.indemnityInsuranceExpiryModel=$event.target.value}}})],1)])],1):_vm._e(),(_vm.$isAuRegion() || _vm.$isNZRegion())?_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"w-full m-5"},[_c('label',[_vm._v("Insurance Certificate")]),_c('div',{class:{
            'insurance-certificate-error': _vm.errors.has('insuranceCertificate'),
          }},[(_vm.createUserData.insuranceCertificate)?_c('div',{staticClass:"mb-4"},[(
                _vm.determineFileType(_vm.createUserData.insuranceCertificate) ===
                'pdf'
              )?_c('div',{staticStyle:{"margin":"auto","width":"50%"}},[_c('a',{attrs:{"href":_vm.createUserData.insuranceCertificate,"target":"_blank"}},[_vm._v("Click here to view file")])]):_c('div',{staticClass:"large-square-img-wrapper",staticStyle:{"margin":"auto"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.createUserData.insuranceCertificate}})])]):_vm._e(),_c('div',{class:{ 'mt-3': _vm.createUserData.insuranceCertificate },staticStyle:{"text-align":"center","position":"relative"}},[_c('label',{staticClass:"vs-con-loading__container",class:{ 'six-pixel': _vm.errors.has('insuranceCertificate') },staticStyle:{"border":"1px solid #cccccc","display":"inline-block","width":"100%","border-radius":"5px","padding":"10px"},attrs:{"id":"insuranceUpload"}},[_vm._v(" Upload Document "),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"file","id":"file-input","hidden":"","data-vv-as":"Insurance certificate","data-vv-validate-on":"change","name":"insuranceCertificate"},on:{"change":function($event){return _vm.uploadImage($event, 'insuranceUpload')}}})])])]),_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"0.75em"}},[_vm._v(_vm._s(_vm.errors.first("insuranceCertificate")))])])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }