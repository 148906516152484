<template>
  <div>
    <label for="doc-type">{{ labelText }}</label>
    <div id="doc-type">
      <ul class="doctor-type-ul">
        <li>
          <input
            @change="$emit('changeDoctorType', 'contract')"
            :checked="checkedVal === 'contract'"
            type="radio"
            id="contract"
            name="doctorType"
          />
          <label for="contract">Contract</label>
        </li>
        <li>
          <input
            @change="$emit('changeDoctorType', 'onCall')"
            :checked="checkedVal === 'onCall'"
            type="radio"
            id="onCall"
            name="doctorType"
          />
          <label for="onCall">On Call</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoctorType",
  props: {
    checkedVal: {
      type: String,
      required: false,
      default: "contract",
    },
    labelText: {
      type: String,
      required: false,
      default: "What type of doctor are we creating?",
    },
  },
};
</script>

<style scoped lang="scss">
#doc-type {
  .doctor-type-ul {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0;

    li {
      float: left;
      margin: 0 5px 0 0;
      width: 100px;
      height: 40px;
      position: relative;
    }

    label,
    input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    input[type="radio"] {
      opacity: 0.01;
      z-index: 100;
    }

    input[type="radio"]:checked + label,
    .Checked + label {
      background: #3dc9b3;
      color: #fff;
    }

    label {
      padding: 5px;
      border: 1px solid #ccc;
      cursor: pointer;
      z-index: 90;
      text-align: center;
      padding-top: 10px;
      font-weight: 600;
      color: #3dc9b3;
    }

    label:hover {
      background: #3dc9b3;
      color: #fff;
    }
  }
}
</style>
